<template>
  <article id="home">

    <section id="top_bg">
    </section>

    <section id="top">
    </section>

    <section id="top2">
      <p>{{$t('Home.introduce.p1')}}</p>
      <p>{{$t('Home.introduce.p2')}}</p>
      <p>{{$t('Home.introduce.p3')}}</p>
    </section>

    <section id="painter">
      <h2>{{$t('Home.as_artist.title')}}</h2>
      <h3>{{$t('Home.as_artist.apple_title')}}</h3>
      <p>{{$t('Home.as_artist.apple_sub_title')}}</p>
      <router-link :to="{name:'Apple'}">
        <img src="/img/universe/2016_01.jpg">
      </router-link>
      <router-link :to="{name:'Apple'}">
        <button>{{$t('Home.as_artist.apple_button')}}</button>
      </router-link>

      <h3>{{$t('Home.as_artist.sabian_title')}}</h3>
      <p>{{$t('Home.as_artist.sabian_sub_title')}}</p>
      <router-link :to="{name:'Sabian'}">
        <img src="/img/sabian/01_06_2000px.jpg">
      </router-link>
      
      <router-link :to="{name:'Sabian'}">
        <button>{{$t('Home.as_artist.sabian_button')}}</button>
      </router-link>
    </section>

    <section id="astrologer">
      <h2>{{$t('Home.as_astrologer.title')}}</h2>
      <img src="https://sabian-calculator.com/img/logo/rainbow_star.svg">
      <p>{{$t('Home.as_astrologer.sabian_calculator_description')}}</p>
      <a href="https://sabian-calculator.com/" target="_blank">
        <button>{{$t('Home.as_astrologer.sabian_calculator_button')}}</button>
      </a>
    </section>

    <section id="programmer">
      <h2>{{$t('Home.as_programmer.title')}}</h2>
      <img src="/img/logo/github.png">
      <p>{{$t('Home.as_programmer.pluto_js_description.p1')}}<a href="https://github.com/rubyfmzk/Pluto" target="_blank">Pluto.js</a>{{$t('Home.as_programmer.pluto_js_description.p2')}}</p>
      <a href="https://github.com/rubyfmzk/Pluto" target="_blank">
        <button>{{$t('Home.as_programmer.pluto_js_button')}}</button>
      </a>
    </section>

    <section id="bio">
      <h2>About me</h2>
      <div class="me">
        <img src="/img/logo/face.jpg">
      </div>
      <div class="text">
        <p>{{$t('Home.about_me.my_story.p1')}}<br>{{$t('Home.about_me.my_story.p2')}}</p>

        <p>{{$t('Home.about_me.my_story.p3')}}</p>

        <p>{{$t('Home.about_me.my_story.p4')}}</p>

        <h3>{{$t('Home.about_me.about_astrology.title')}}</h3>
        <p>{{$t('Home.about_me.about_astrology.p1')}}</p>

        <h3 id="about_sabian_symbols">{{$t('Home.about_me.about_sabian.title')}}</h3>
        <p>{{$t('Home.about_me.about_sabian.p1')}}</p>
      </div>
    </section>

  </article>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2{
  font-size: 24px;
  padding: 0px 2vw 0;
}
h3{
  font-size: 20px;
  padding: 100px 2vw 0;
}
h2 + p{
  padding: 20px 2vw 0;
}

section{
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px;
}

button{
    border: solid 2px #485973;
    border-radius: 2px;
    background: rgb(156,170,170,0.3);
    padding: 6px 5px 6px 32px;
    color: #a2aed6;
    font-size: 12px;
    cursor: pointer;

    &:after{
      content: "〉";
      margin: 0 0 0 20px;
    }
}

#top_bg{
  padding: 0;
  position: fixed;
  background: url(/img/sabian/01_01_2000px.jpg) #000;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 83%;
}

#top_bg h1{
  position: fixed;
  left: 0;
  padding: 2vw;
  font-size: 20px;
}

@media (min-width: 768px){
  #top_bg{
    background-position: 50% 15%;
  }
}

#top{
  position: relative;
  height: 100vh;
  padding-top: 0;
  padding-bottom: 0;
}

#top2, #top3{
  position: relative;
  background: rgb(27 23 33 / 70%);
  padding: 30px 4vw;
  line-height: 2.6em;

  p{
    margin: 1.6em auto;
    top: calc(50% - 25px);
    right: 0;
    left: 0;
  }
}

@media (min-width: 768px){
  #top2{
    p{
    }
  }
}

#top2_top3{
  height: 100vh;
}

#topic{
  background: #211a35;
}

#img_ruby{
    background: url(/img/sabian/01_06_2000px.jpg);
    width: 100vw;
    height: 50vh;
    background-attachment: fixed;
    background-size: cover;
}
#img_bak{
    background: url(/img/universe/chain_2016.jpg);
    width: 100vw;
    height: 50vh;
    background-attachment: fixed;
    background-size: cover;
}

#painter{
  background: #000;

  img{
    display: block;
    max-height: 70vh;
    max-width: 80%;
    margin: 30px auto;
  }

  p{
    margin: 10px 0 30px;
  }

  h3 + p{
    font-size: 13px;
    color: #aba89d;
  }
}

#astrologer{
  background: #191c29;

  img{
    width: 60px;
    display: block;
    margin: 40px auto;
  }

  p{
    margin: 0 4vw 30px;
  }
}

#programmer{
  background: #000;

  img{
    width: 90px;
    display: block;
    margin: 40px auto;
  }

  p{
    margin: 0 4vw 30px;
  }
}

#bio{
  background: #161609;

  p + p{
    padding: 32px 0 0 0;
  }

  .text{
    display: inline-block;
    max-width: 600px;
    text-align: left;
  }

  h3{
    font-size: 20px;
    padding: 20px 0 20px 0;
    margin: 30px 0 0 0;
    text-align: center;
  }

  .me{
      margin: 50px;
  }

  .me img{
    border-radius: 50%;
    border: solid 5px #000;
    width: 130px;
    display: block;
    margin: auto;
  }
}


</style>